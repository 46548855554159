import { IStrings } from '../types/localeTypes';

export const ES_STRINGS: IStrings = {
  home: {
    id: 'inicio',
    name: 'Inicio',
    route: '/',
  },
  sections: {
    platforms: {
      id: 'plataformas',
      name: 'Plataformas',
      showInNav: 'false',
      route: '/#plataformas',
    },
    benefits: {
      id: 'beneficios',
      name: 'Beneficios',
      showInNav: 'true',
      route: '/#beneficios',
    },
    testimonials: {
      id: 'testimonios',
      name: 'Testimonios',
      showInNav: 'true',
      route: '/#testimonios',
    },
    contact: {
      id: 'contacto',
      name: 'Contacto',
      showInNav: 'true',
      route: '/#contacto',
    },
    legal: {
      id: 'avisoLegal',
      name: 'Aviso legal',
      showInNav: 'false',
      route: '/aviso-legal',
    },
    privacy: {
      id: 'avisoPrivacidad',
      name: 'Aviso de privacidad',
      showInNav: 'false',
      route: '/aviso-privacidad',
    },
  },
  platforms: {
    title: 'Todo lo que necesitas en 3 plataformas geniales',
    imageUrl: '../img/platforms/banner.png',
    carousel: {
      0: {
        title: 'Gestión de Talento',
        description:
          'Gestiona  el  talento  en  un  solo  lugar  automatizando  tu departamento de recursos humanos y creando una organización extraordinaria.',
        imageUrl: '../img/platforms/primerabase.png',
        linkTo: 'https://impulso-capital-humano.auth.primerabase.com/auth/login',
      },
      1: {
        title: 'Kiosco de Incidencias',
        description:
          'Interactúa directamente con nuestro sistema de operación de nóminas a manera de cargarlas, editarlas, aprobarlas y finalmente descargar recibos, facturas y XML’s de manera autónoma.',
        imageUrl: '../img/platforms/nomen.png',
        linkTo: 'https://imperium.nominaenlanube.com/nomen/kiosco_auth/login',
      },
      2: {
        title: 'Salario al Instante',
        description:
          'Mejora la calidad de vida de los colaboradores enfocándose en los 3 pilares fundamentales del bienestar: físico, mental y financiero.',
        imageUrl: '../img/platforms/twispi.svg',
        linkTo: '',
      },
    },
  },
  benefits: {
    title: 'Beneficios',
    list: {
      0: {
        headline: 'ADMINISTRADOR DE TALENTOS',
        title: 'Construye un equipo de ganadores',
        description:
          'Poderoso módulo para gerenciar tus procesos de administración de talento, desde la captación hasta la baja.',
        imageUrl: '../img/benefits/team.png',
      },
      1: {
        headline: 'DESARROLLO ORGANIZACIONAL',
        title: 'Dinamiza tu desempeño y crece tu organización',
        description:
          'Dinamiza la comunicación en tu empresa, mide el desempeño de tus colaboradores y reconoce sus logros.',
        imageUrl: '../img/benefits/organization.png',
      },
      2: {
        headline: 'NÓMINA',
        title: 'Modela y procesa',
        description: 'Sistema para modelar nóminas y enviar su procesamiento.',
        imageUrl: '../img/benefits/model.png',
      },
      3: {
        headline: 'SALUD FINANCIERA',
        title: 'Salario al instante',
        description: 'Tus colaboradores pueden solicitar su salario ya trabajado en el momento que lo deseen.',
        imageUrl: '../img/benefits/salary.png',
      },
    },
  },
  alliades: {
    title: 'Sumando con los Mejores',
    list: {
      0 : {
        imageUrl: '../img/alliades/onsefi.png',
        linkTo: '',
      },
      1: {
        imageUrl: '../img/alliades/firmeza.png',
        linkTo: '',
      },
      2: {
        imageUrl: '../img/alliades/sura.svg',
        linkTo: '',
      },
      3: {
        imageUrl: '../img/alliades/busdoc.png',
        linkTo: '',
      },
      4: {
        imageUrl: '../img/alliades/twispi.svg',
        linkTo: '',
      },
      5: {
        imageUrl: '../img/alliades/primerabase.png',
        linkTo: '',
      },
      6: {
        imageUrl: '../img/alliades/nomen.png',
        linkTo: '',
      },
    },
  },
  testimonials: {
    title: 'Escucha lo que dicen nuestros clientes',
    description: 'Con mas de 100 clientes a la fecha hemos podido generar un gran impacto sobre ellos.',
    carousel: {
      0: {
        fullName: 'Román González',
        position: 'Gerente de proyectos en inMotion Consulting Group',
        quote:
          'Si la gestión es engorrosa, impulsa a la gente a centralizar su información de nómina y la gestión de talento humano, para concentrar ese conocimiento y el talento a lo largo del tiempo.',
        imageUrl: '../img/testimonials/MarianoPasqualini.png',
      },
      1: {
        fullName: 'Alicia Moreno',
        position: 'Gerente de R.H. de Lumina',
        quote:
          'Tenemos claridad en los procesos que debemos de seguir, ya que todo lo que necesito está en un solo lugar.',
        imageUrl: '../img/testimonials/AliciaMoreno.png',
      },
      2: {
        fullName: 'Daisy Pizano',
        position: 'Coordinadora de R.H. de InMotion Consulting',
        quote:
          'Lo que más me gustó es que me olvidé de las dificultades, ya que gracias a ustedes pude gestionar los recursos de mi empresa enfocándonos en nuestro negocio.',
        imageUrl: '../img/testimonials/DaisyPizano.png',
      },
    },
  },
  contact: {
    title: '¿Te gustaría obtener más información?',
    contactUs: '¡Contáctanos!',
    nameText: 'Nombre',
    emailText: 'Correo electrónico',
    phoneText: 'Teléfono',
    messageText: 'Mensaje',
    imageUrl: '../img/contact/banner.png',
    successfulSubmission: 'Se envió el formulario exitosamente.',
    failedSubmission: 'No se pudo enviar el formulario, inténtalo de nuevo.',
    invalidEmail: 'Ingresa un correo electrónico válido',
    invalidPhoneNumber: 'Ingresa un teléfono válido',
  },
  footer: {
    sitemap: 'MAPA DEL SITIO',
    contactData: {
      title: 'DATOS DE CONTACTO',
      address: {
        text: 'Av. Insurgentes Sur 427, piso 11, Hipodromo, CP 06100, Cuauhtémoc, CDMX.',
        url: 'https://maps.app.goo.gl/1cMkv25mdMZktb1A6',
      },
      email: 'contacto@impulsocapitalhumano.com',
    },
    follow: {
      title: 'SÍGUENOS',
      facebookUrl: '',
      twitterUrl: '',
      instagramUrl: '',
    },
  },
  shared: {
    whatsappUrl:
      'https://wa.me/5579105857?text=Hola,%20me%20comunico%20para%20solicitar%20información%20sobre%20los%20servicios%20que%20ofrecen%20en%20la%20página%20Impulso%20Capital%20Humano',
    sendButton: 'ENVIAR',
    enterButton: 'IR',
    legalContent: `
    <p>I. DATOS DE IDENTIFICACIÓN DEL RESPONSABLE</p>
    <ol style="list-style: lower-alpha;">
      <li>Denominación social es IMPULSO CAPITAL HUMANO inicio de labores desde el 09 de julio de 2020.</li>
      <li>Su RFC es HUM200709EJ8</li>
      <li>Domicilio: AV CHAPULTEPEC, 480, ROMA NORTE, CUAUHTEMOC, CIUDAD DE MEXICO, C.P. 06700.</li>
      <li>Teléfono: 555 666 23 43</li>
      <li>Email: contacto@impulsocapitalhumano.com</li>
    </ol>
    `,
    privacyContent: `
    <p>
      En cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los
      Particulares y su Reglamento (la LEY), hacemos de su conocimiento que <strong>“IMPULSO CAPITAL HUMANO”</strong>, es una marca
      comercial constituida conforme a la legislación mexicana la cual inició labores en fecha 09 de julio de 2020. Estando a cargo de
      dicha marca, la razón social denominada “<strong style="font-size: 10px">HUMANATID, S.A. DE C.V.</strong>”, a quien de ahora en adelante se denominará como
      <strong>“IMPULSO CAPITAL HUMANO”,</strong> misma que tiene los siguientes datos:
    </p>
    <br />
    <ol style="list-style: disc;">
      <li>Denominación social es IMPULSO CAPITAL HUMANO inicio de labores desde el 09 de julio de 2020.</li>
      <li>Su RFC es HUM200709EJ8</li>
      <li>Domicilio: AV CHAPULTEPEC, 480, ROMA NORTE, CUAUHTEMOC, CIUDAD DE MEXICO, C.P. 06700.</li>
      <li>Teléfono: 555 666 23 43</li>
      <li>Email: contacto@impulsocapitalhumano.com</li>
    </ol>
    </br>
    <p>
      Y es el responsable de recabar sus datos personales (los DATOS) así como la protección y uso de estos, toda vez que
      <strong>IMPULSO CAPITAL HUMANO</strong> es el propietario e integrador del sitio <em>https://www.impulsocapitalhumano.com/</em> (en
      adelante el SITIO), el cual accesible en todo el mundo a través del Internet para los visitantes, y para sus Clientes
      (en adelante “USUARIO”) estos últimos tendrán acceso al contenido total del sitio. El USUARIO, que haga uso del
      SITIO es libre de navegar, explorar en el mismo obteniendo la facultad de poder seleccionar libremente el
      producto y/o servicio que sea de su agrado, conveniencia y/o necesidad.
    </p>
    <br />
    <p>
      Así mismo hacemos de su conocimiento que los DATOS que podemos recabar de usted, son tratados de forma
      estrictamente confidencial los cuales se en listan a continuación:
    </p>
    <br />
    <ol>
      <li>Nombre completo.</li>
      <li>E-Mail.</li>
      <li>Número de teléfono.</li>
      <li>Domicilio particular o de trabajo</li>
      <li>Intereses.</li>
    </ol>
    <br />
    <p>
      <strong>IMPULSO CAPITAL HUMANO</strong> se compromete a proteger la privacidad del usuario en el SITIO y a facilitar la
      tecnología de desarrollo necesaria para que pueda disfrutar de una conexión segura y eficaz. Nuestro SITIO
      emplea la información recabada con el fin de proporcionar el mejor servicio posible, particularmente para
      mantener un registro de usuarios, de pedidos en caso de que aplique, y mejorar nuestros productos y servicios.
      Se informa al USUARIO que es posible que sean enviados correos electrónicos eventualmente a través de nuestro
      sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para
      usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted
      proporcione y podrán ser cancelados en cualquier momento.
    </p>
    <br />
    <p>
      Así mismo se informa que <strong>IMPULSO CAPITAL HUMANO</strong> puede recopilar de forma automática información sobre el
      hardware y el software de su equipo, misma que podrá ser compartida con otros sitios pertenecientes a <strong>IMPULSO
      CAPITAL HUMANO.</strong> Entre los diversos datos que pueden ser recopilados se encuentran la dirección IP, tipo de
      explorador, nombres de dominio, tiempo de acceso, y direcciones de sitios web visitados. <strong>IMPULSO CAPITAL
      HUMANO</strong> utilizará dicha información para administrar el servicio que ofrece a sus usuarios, para mantener la
      calidad de dicho servicio, y obtener estadísticas genéricas relativas al uso de los sitios web de <strong>IMPULSO CAPITAL
      HUMANO.</strong> Recuerde que toda información personal que identifica al usuario, revelada de manera directa o por
      cualquier medio de contacto o foro público de conexión en línea, podrá ser recopilada y utilizada.
    </p>
    <br />    
    <p>
      Dichos DATOS serán utilizados para las siguientes finalidades:
    </p>
    <br />
    <ol>
      <li>Proveer los servicios ofrecidos en el SITIO.</li>
      <li>Informar sobre cambios llevados a cabo en el SITIO.</li>
      <li>Informar sobre alguna actualización de la aplicación en el SITIO.</li>
      <li>Para fines Administrativos.</li>
      <li>Con fines Estadísticos y/o de Prospección, así como evaluar y mejorar la calidad en el SITIO.</li>
      <li>
        Elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar
        evaluaciones periódicas de nuestros productos y servicios.
      </li>
      <li>Contactarlo para algún tema relacionado con el presente Aviso de Privacidad.</li>
      <li>Cualquier finalidad análoga o compatible con las anteriores.</li>
    </ol>
    <br />
    <p>
      Asimismo, y salvo en las excepciones previstas en el artículo 37 de la Ley, se podrán transmitir sus DATOS a
      terceros, siempre y cuando, se requiera dicha trasmisión para ofrecerle un mejor servicio.
    </p>
    <br />
    <p><strong><u>USO DE LOS COOKIES</u></strong></p>
    <p>
      Una cookie se refiere a un fichero que se envía con la finalidad de solicitar permiso para almacenarse en su
      ordenador, y sirve para tener información respecto al tráfico web, así como facilita visitas futuras a una página web
      de interés. Otra función que tienen las cookies es que con ellas las páginas web pueden reconocerte y por tanto
      brindarte un servicio personalizado en su página web. Nuestro sitio web emplea las cookies para poder identificar
      las secciones de nuestra página que son visitadas y su frecuencia. Esta información es empleada únicamente para
      análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en
      cualquier momento desde su ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor servicio en
      nuestro sitio web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo
      quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de
      navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede
      cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar
      algunos de nuestros servicios en la página web.
    </p>
    <br />
    <p><strong><u>DERECHOS</u></strong></p>
    <p>
      <strong>IMPULSO CAPITAL HUMANO</strong> y su logotipo es una marca registrada dentro de los Estados Unidos Mexicanos, el uso
      indebido de será sancionado conforme a las Leyes aplicables vigentes. No nos hacemos responsables por los
      daños y perjuicios ocasionados por el mal servicio de personas, negocios o teléfonos que no estén indicados
      en esta página.
    </p>
    <br />
    <p><strong><u>EXCLUSIÓN DE USUARIOS.</u></strong></p>
    <p>
      <strong>IMPULSO CAPITAL HUMANO</strong> se reserva el derecho de excluir el acceso a aquellos USUARIOS de los que se sospeche,
      realicen o pretendan realizar mal uso del SITIO y/o de la información mostrada en el mismo, sea de cualquier
      índole o que incumplan con las condiciones que aquí se expresan sin necesidad de previo aviso.
    </p>
    <br />
    <p>
      <strong>IMPULSO CAPITAL HUMANO</strong> se reserva el derecho de excluir y/o eliminar los comentarios o aportaciones de
      cualquier índole que se obtengan del USUARIO a través de foros, chats u otras herramientas de participación que
      atenten contra la dignidad de alguna persona, sean discriminatorios, xenófobos, racistas, pornográficos, que
      atenten contra la juventud o la infancia, el orden o la seguridad pública o que a su juicio no resultaran adecuados
      para su publicación.
    </p>
    <br />
    <p>
      <strong>IMPULSO CAPITAL HUMANO</strong> perseguirá el incumplimiento de las presentes condiciones, así como cualquier
      utilización indebida de su SITIO ejerciendo todas las acciones civiles y penales que le puedan corresponder en
      derecho.
    </p>
    <br />
    <p><strong><u>DERECHOS ARCO</u></strong></p>
    <p>
      Para prevenir el acceso no autorizado a sus DATOS y con el fin de asegurar que la información sea utilizada para
      los fines establecidos en este Aviso de Privacidad, <strong>IMPULSO CAPITAL HUMANO</strong> establece diversos
      procedimientos con la finalidad de evitar el uso o divulgación no autorizada de los mismos, por lo anterior se hace
      de su conocimiento que para poder ejercer sus derechos ARCO (Acceso, Rectificación, Cancelación u Oposición) al
      tratamiento de sus DATOS, deberá presentar una solicitud conforme a lo establecido en el artículo 29 de la LEY
      citada, incluyendo en la misma nombre, número telefónico, dirección y correo electrónico.
    </p>
    <br />
    <p>
      Su solicitud deberá ser enviada al correo electrónico: <strong>contacto@impulsocapitalhumano.com</strong> por lo que en el plazo
      señalado en el Art. 32 de la LEY en comento le informará sobre la procedencia de la misma.
    </p>
    <br />
    <p>
      <strong>IMPULSO CAPITAL HUMANO</strong> se reserva el derecho de cambiar, modificar, complementar y/o alterar el presente
      Aviso de Privacidad, en cualquier momento, atendiendo a novedades legislativas, políticas internas o nuevos
      requerimientos para la prestación u ofrecimiento de nuestros servicios, dichas modificaciones serán
      oportunamente informadas por cualquier medio de comunicación que establece la legislación en la materia.
    </p>
    <br />
    <p><strong><u>JURISDICCIÓN</u></strong></p>
    <p>
      El usuario al hacer uso del SITIO, acepta de manera expresa, someterse en caso de cualquier controversia, a la
      jurisdicción de los tribunales de la Ciudad de México, en los Estados Unidos Mexicanos; así como a las leyes
      aplicables para el caso concreto vigentes en dicho lugar, renunciando expresamente a cualquier otra jurisdicción
      que por motivo de su nacionalidad o domicilio pudiera corresponder.
    </p>
    <br />
    <p style="text-align: end">Fecha de última actualización: 22 de febrero 2024.</p>
    `,
  },
};
