import { HomePage } from '../components/pages/HomePage';
import { LegalPage } from '../components/pages/LegalPage';
import { PrivacyPage } from '../components/pages/PrivacyPage';
import { Page } from '../types/app';

export const HOME_PAGE: Page = {
  id: 'home.id',
  name: 'home.name',
  route: 'home.route',
  component: <HomePage />,
};
export const LEGAL_PAGE: Page = {
  id: 'sections.legal.id',
  name: 'sections.legal.name',
  route: 'sections.legal.route',
  component: <LegalPage />,
};
export const PRIVACY_PAGE: Page = {
  id: 'sections.privacy.id',
  name: 'sections.privacy.name',
  route: 'sections.privacy.route',
  component: <PrivacyPage />,
};

export const PAGES: Page[] = [HOME_PAGE, LEGAL_PAGE, PRIVACY_PAGE];
