import { EMPTY_CONTACT_FORM } from '../constants/app';
import { ApiError } from '../types/apiResponseError';
import { postRequest } from './api';

export const postContactForm = async (
  contactForm: typeof EMPTY_CONTACT_FORM,
  gRecaptchaResponse: string
): Promise<{ error?: ApiError }> => {
  const response = await postRequest('contact-us/', {
    body: { ...contactForm, gRecaptchaResponse },
  });

  return {
    error: response?.error,
  };
};
