import { NAVBAR_HEIGHT } from '../../constants/ui';
import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';

export function PrivacyPage() {
  const { classes, concatenateClasses, css, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const titleStyles = css({
    marginTop: '4px',
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.colors.primary[0],
  });

  const descriptionStyles = css({
    marginTop: '8px',
    fontSize: '16px',
    textAlign: 'justify',
    [mediaQueries.xxl]: {
      marginTop: '12px',
      fontSize: '18px',
    },
  });

  const sectionStyles = concatenateClasses(classes.container, classes.section, classes.flexColumn);

  const wrapperStyles = concatenateClasses(
    classes.backgroundLight,
    css({ boxSizing: 'border-box', minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)` })
  );

  return (
    <div className={wrapperStyles}>
      <section className={sectionStyles}>
        <h1 className={titleStyles}>{strings.sections.privacy.name}</h1>
        <div className={descriptionStyles} dangerouslySetInnerHTML={{ __html: strings.shared.privacyContent }} />
      </section>
    </div>
  );
}
