import { ChangeEvent } from 'react';
import { useStyles } from '../../hooks/useStyles';
import { InputComponent } from '../../types/componentTypes';

export function Input({
  onChange,
  type = 'text',
  classNames,
  value,
  placeholder,
  isTextArea = false,
}: Readonly<InputComponent>) {
  const { concatenateClasses, css, theme } = useStyles();

  const allStyles = concatenateClasses(
    css({
      width: '100%',
      resize: 'none',
      boxSizing: 'border-box',
      padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
      border: '2px solid white',
      borderRadius: theme.sizing.xs,
      [':focus-visible']: {
        outline: 'none',
      },
      [':focus']: {
        border: `2px solid ${theme.colors.primary[0]}`,
      },
    }),
    classNames ?? ''
  );

  const inputProps = {
    value,
    onChange: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value),
    type,
    className: allStyles,
    placeholder: placeholder,
  };

  if (isTextArea) {
    return <textarea {...inputProps} rows={4} />;
  }

  return <input {...inputProps} />;
}
