import { useStyles } from '../../../hooks/useStyles';

type Props = {
  benefit: {
    title: string;
    headline: string;
    description: string;
    imageUrl: string;
  };
  invPosition?: boolean;
};
export function BenefitCard({ benefit, invPosition = false }: Readonly<Props>) {
  const { classes, css, concatenateClasses, mediaQueries, theme, styles } = useStyles();

  const benefitCardContentStyles = concatenateClasses(
    classes.fullWidth,
    css({
      ...styles.flexColumnStyles,
      gap: theme.spacing.md,
      [mediaQueries.xl]: {
        flexDirection: invPosition ? 'row-reverse' : 'row',
        gap: theme.spacing.xl,
      },
    })
  );

  const columLContentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'center',
      flex: 5,
    })
  );

  const columSmContentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: invPosition ? 'end' : 'start',
      flex: 2.5,
      justifyContent: 'center',
      textAlign: 'center',
      [mediaQueries.xl]: {
        textAlign: invPosition ? 'end' : 'start',
      },
    })
  );

  const labelStyles = concatenateClasses(
    classes.fullWidth,
    css({
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '2px',
      lineHeight: 1,
      textDecoration: 'underline',
      [mediaQueries.xxl]: {
        fontSize: theme.sizing.lg,
      },
    })
  );

  const titleStyles = concatenateClasses(
    classes.fullWidth,
    css({
      fontSize: theme.sizing.xl,
      fontWeight: '600',
      marginTop: theme.spacing.sm,
      [mediaQueries.xxl]: {
        fontSize: theme.sizing.xxl,
      },
    })
  );

  const descriptionStyles = concatenateClasses(
    classes.fullWidth,
    css({
      fontSize: theme.sizing.lg,
      letterSpacing: '1px',
      marginTop: theme.spacing.lg,
      [mediaQueries.xxl]: {
        fontSize: '20px',
      },
    })
  );

  const imgStyles = concatenateClasses(
    classes.fullWidth,
    css({
      maxWidth: '600px',
      [mediaQueries.xl]: {
        maxWidth: 'none',
      },
    })
  );

  return (
    <div className={benefitCardContentStyles}>
      <div className={columSmContentStyles}>
        <p className={labelStyles}>{benefit.headline}</p>
        <p className={titleStyles}>{benefit.title}</p>
        <p className={descriptionStyles}>{benefit.description}</p>
      </div>
      <div className={columLContentStyles}>
        <img src={benefit.imageUrl} alt="Benefit" className={imgStyles} />
      </div>
    </div>
  );
}
