import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { ContactForm } from './contact/ContactForm';

export function ContactSection() {
  const { classes, concatenateClasses, css, theme, styles, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const titleStyles = concatenateClasses(
    classes.fullWidth,
    classes.sectionTitle,
    css({
      color: theme.colors.primary[0],
      fontWeight: 'bold',
      lineHeight: '1.3',
      textAlign: 'center',
    })
  );
  const contactContentStyles = concatenateClasses(
    css({
      ...styles.flexColumnStyles,
      gap: theme.spacing.xl,
      [mediaQueries.xl]: { flexDirection: 'row', gap: '40px' },
    })
  );
  const sectionStyles = concatenateClasses(classes.container, classes.section);

  return (
    <div className={classes.backgroundPrimaryLight}>
      <section id={strings.sections.contact.id} className={sectionStyles}>
        <h1 className={titleStyles}>{strings.contact.title}</h1>
        <div className={contactContentStyles}>
          <div className={css({ flex: 1 })}>
            <img src={strings.contact.imageUrl} alt="Contact" className={classes.imgResponsive} />
          </div>
          <ContactForm />
        </div>
      </section>
    </div>
  );
}
