export const environment = {
  production: false,
  awsProjectRegion: 'us-east-1',
  awsAPIEndpointName: 'apiImpulso',
  awsAPIEndpointUrl: 'https://ed2s8yxst2.execute-api.us-east-1.amazonaws.com/v1/inmotion/',
  reportSettings: {
    routeEvent: {
      maxTimeInterval: 1, //value in months
    },
    positionHistory: {
      maxSeniorityTime: 6, //value in months
    },
  },
};
