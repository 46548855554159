import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import App from './App';
import awsConf from './aws-exports';
import './index.css';

Amplify.configure(awsConf);

const engine = new Styletron();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <StyletronProvider value={engine}>
          <App />
        </StyletronProvider>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>
);
