import request from 'axios';
import { ApiError, InvalidError } from '../types/apiResponseError';

export const getErrorMessage = (error: unknown): ApiError => {
  if (request.isAxiosError(error) && error.response) {
    //The error message when is validation error
    if (error.response.status === 422) return error.response.data.detail;
    //The error message when the error is from the client
    if (error.response.status >= 400 && error.response.status < 500) return error.response.data;
    //The error message when the error is from server
    if (error.response.status >= 500) return 'Ups.. algo salio mal, intentalo de nuevo en unos minutos';
  }
  //Default error message
  return 'Something went wrong';
};

export const isInvalidError = (error: ApiError): error is InvalidError => {
  return !!(error as InvalidError)?.error;
};
