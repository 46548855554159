import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { BaseLayout } from './components/layouts/BaseLayout';
import { EN_LANGUAGE, ES_LANGUAGE } from './constants/locales';
import { PAGES } from './constants/routes';
import { usePageRoute } from './hooks/usePageRoute';
import { useStrings } from './hooks/useStrings';

function App() {
  const { getRoutePage } = usePageRoute();
  const { strings } = useStrings();

  const router = createBrowserRouter([
    {
      id: 'app',
      element: <BaseLayout />,
      children: [
        {
          id: ES_LANGUAGE,
          children: [...PAGES.map((page) => getRoutePage(page, ES_LANGUAGE))],
        },
        {
          id: EN_LANGUAGE,
          path: `/${EN_LANGUAGE}`,
          children: [...PAGES.map((page) => getRoutePage(page, EN_LANGUAGE))],
        },
      ],
    },
    {
      id: '404',
      path: '/*',
      element: <Navigate to={strings.home.route} />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
