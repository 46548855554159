import { Fragment, useState } from 'react';
import { useStyles } from '../../../hooks/useStyles';

type Props = {
  options: React.ReactNode[];
  hiddenArrows?: boolean;
};
export function Carousel({ options, hiddenArrows = false }: Readonly<Props>) {
  const { css, theme } = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const slide = css({
    display: 'flex',
    position: 'relative',
    height: '100%',
  });

  const slideInner = css({
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  });

  const slideItem = css({
    position: 'absolute',
    opacity: 0,
    transition: 'opacity 0.8s ease-out',
    width: '100%',
  });

  const slideItemActive = css({
    position: 'static',
    opacity: '100%',
    margin: 'auto 0px',
    width: '100%',
  });

  const slideControl = css({
    background: 'transparent',
    borderRradius: '50%',
    color: theme.colors.primary[0],
    cursor: 'pointer',
    display: 'none',
    fontSize: '40px',
    height: '40px',
    lineHeight: '35px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    textAlign: 'center',
    width: '40px',
    zIndex: 10,
  });

  const slideControlActive = (index: number) => {
    if (index !== activeTab) return;
    return css({
      display: 'block !important',
    });
  };

  const prev = css({
    left: '2%',
  });

  const next = css({
    right: '2%',
  });

  const slideIndicator = css({
    lineStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'absolute',
    bottom: '-2%',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: 10,
  });

  const slideIndicatorItem = css({
    display: 'inline-block',
    margin: '0 5px',
  });

  const slideCirculo = css({
    width: '10px',
    height: '10px',
    color: theme.colors.primary[0],
    border: '1px solid ' + theme.colors.primary[0],
    borderRadius: '50%',
    cursor: 'pointer',
  });

  const slideCirculoActive = css({
    background: theme.colors.primaryBackground,
  });

  return (
    <div className={slide}>
      <div className={slideInner}>
        {options.map((item, index) => (
          <Fragment key={index}>
            <input
              type="radio"
              id={`slide-${index + 1}`}
              hidden
              name="slide"
              onChange={() => setActiveTab(index)}
              checked={activeTab === index}
            />
            <div className={`${activeTab === index ? slideItemActive : slideItem}`}>{item}</div>
            {!hiddenArrows && (
              <>
                <label
                  htmlFor={`slide-${index === 0 ? options.length - 1 : index}`}
                  className={`${slideControl} ${prev} ${slideControlActive(index)}`}
                >
                  ‹
                </label>
                <label
                  htmlFor={`slide-${index === options.length - 1 ? 1 : index + 2}`}
                  className={`${slideControl} ${next} ${slideControlActive(index)}`}
                >
                  ›
                </label>
              </>
            )}
          </Fragment>
        ))}
        <ol className={slideIndicator}>
          {options.map((_, index) => (
            <li key={`indicador-${index}`} className={slideIndicatorItem}>
              <label htmlFor={`slide-${index + 1}`}>
                <div className={`${slideCirculo} ${index === activeTab && slideCirculoActive}`}></div>
              </label>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
