import { StyleObject, useStyletron } from 'styletron-react';
import THEME from '../constants/theme';

export function useStyles() {
  const [css] = useStyletron();
  const theme = THEME;

  const mediaQueries = {
    xs: `@media screen and (min-width: ${theme.breakpoints.xs})`,
    sm: `@media screen and (min-width: ${theme.breakpoints.sm})`,
    md: `@media screen and (min-width: ${theme.breakpoints.md})`,
    lg: `@media screen and (min-width: ${theme.breakpoints.lg})`,
    xl: `@media screen and (min-width: ${theme.breakpoints.xl})`,
    xxl: `@media screen and (min-width: ${theme.breakpoints.xxl})`,
  };

  const imgResponsive = css({
    maxWidth: '100%',
  });

  const flexColumnStyles: StyleObject = {
    display: 'flex',
    flexDirection: 'column',
  };

  const flexColumn = css(flexColumnStyles);

  const flexRow = css({
    display: 'flex',
  });

  const flexCentered = css({
    alignItems: 'center',
    justifyContent: 'center',
  });

  const link = css({
    color: 'inherit',
    textDecoration: 'none',
  });

  const container = css({
    maxWidth: '1408px',
    margin: '0 auto',
    paddingRight: '5%',
    paddingLeft: '5%',
    [mediaQueries.lg]: {
      paddingRight: '10%',
      paddingLeft: '10%',
    },
  });

  const section = css({
    paddingTop: '100px',
    paddingBottom: '100px',
  });

  const button = css({
    borderRadius: theme.sizing.xs,
    fontWeight: '600',
    letterSpacing: '1px',
    transition: '0.3s background-color',
  });

  const textPrimary = css({
    color: theme.colors.primary[0],
  });

  const textSecondary = css({
    color: theme.colors.secondary[0],
  });

  const fullWidth = css({
    width: '100%',
  });

  const sectionTitle = css({
    fontSize: '34px',
    fontWeight: '500',
    lineHeight: '1',
    marginBottom: '40px',
    [mediaQueries.xl]: {
      marginBottom: '60px',
      fontSize: '48px',
    },
  });

  const backgroundLight = css({
    backgroundColor: theme.colors.lightBackground,
  });

  const backgroundPrimary = css({
    backgroundColor: theme.colors.primaryBackground,
  });

  const backgroundPrimaryLight = css({
    backgroundColor: theme.colors.primaryLightBackground,
  });

  const displayBlock = css({
    display: 'block',
  });

  const concatenateClasses = (...classes: string[]) => {
    return classes.join(' ');
  };

  return {
    concatenateClasses,
    css,
    mediaQueries,
    theme,
    classes: {
      backgroundLight,
      backgroundPrimary,
      backgroundPrimaryLight,
      button,
      container,
      displayBlock,
      flexCentered,
      flexColumn,
      flexRow,
      fullWidth,
      imgResponsive,
      link,
      section,
      sectionTitle,
      textPrimary,
      textSecondary,
    },
    styles: {
      flexColumnStyles,
    },
  };
}
