import { useEffect } from 'react';
import { useMatches } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { EN_LANGUAGE, ES_LANGUAGE } from '../constants/locales';
import { currentLanguageState } from '../storage/localeStates';

export function AppProvider() {
  const matches = useMatches();
  const setLanguage = useSetRecoilState(currentLanguageState);

  useEffect(() => {
    if (matches.find((match) => match.id === EN_LANGUAGE)) {
      setLanguage(EN_LANGUAGE);
    } else {
      setLanguage(ES_LANGUAGE);
    }
  }, [matches]);

  return null;
}
