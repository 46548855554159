import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollerProvider() {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    if (!hash) return;
    const element = document.getElementById(hash.substring(1));
    setTimeout(() => {
      element?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }, [hash]);

  useEffect(() => {
    if (hash || !pathname) return;
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [hash, pathname]);

  return null;
}
