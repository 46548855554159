import { environment } from './environment';

const awsConf = {
  aws_project_region: environment.awsProjectRegion,
  API: {
    endpoints: [
      {
        name: environment.awsAPIEndpointName,
        endpoint: environment.awsAPIEndpointUrl,
      },
    ],
  },
};

export default awsConf;
