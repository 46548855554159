import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';

export function AlliadesSection() {
  const { classes, css, concatenateClasses, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();

  const titleStyles = concatenateClasses(
    classes.fullWidth,
    classes.sectionTitle,
    css({
      color: theme.colors.primary[0],
      fontWeight: 'bold',
      lineHeight: '1.3',
      marginBottom: '32px',
      textAlign: 'center',
    })
  );

  const sectionContent = concatenateClasses(
    classes.fullWidth,
    css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      [mediaQueries.sm]: {
        gridTemplateColumns: 'repeat(3, 33.33%)',
      },
      [mediaQueries.xl]: {
        gridTemplateColumns: 'repeat(4, 25%)',
      },
    })
  );

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  const alliadeCardStyles = css({
    boxShadow: '0px 0px 8px 0px #dfdfdf',
    padding: '30px 20px',
    borderRadius: theme.sizing.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px',
    [mediaQueries.xl]: {
      margin: '20px',
      padding: '50px 30px',
    },
  });

  return (
    <section className={sectionStyles}>
      <h1 className={titleStyles}>{strings.alliades.title}</h1>
      <div className={sectionContent}>
        {Object.values(strings.alliades.list).map((item, index) => (
          <div className={alliadeCardStyles} key={index}>
            <img
              src={item.imageUrl}
              alt="Alliade"
              className={concatenateClasses(
                classes.fullWidth,
                css({
                  height: '50px',
                  objectFit: 'contain',
                })
              )}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
