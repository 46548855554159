import { Helmet } from 'react-helmet-async';
import { Outlet, useMatches } from 'react-router-dom';
import { PAGES } from '../../constants/routes';
import { NAVBAR_HEIGHT } from '../../constants/ui';
import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { AppProvider } from '../../providers/AppProvider';
import { ScrollerProvider } from '../../providers/ScrollerProvider';
import { Footer } from '../shared/Footer';
import { Navbar } from '../shared/Navbar';

export function BaseLayout() {
  const { css, mediaQueries } = useStyles();
  const matches = useMatches();
  const { strings, getStringByKey } = useStrings();
  const pageId = matches[matches.length - 1].id;

  const title = (() => {
    const pageNameKey = PAGES.find((p) => getStringByKey(p.id) === pageId)?.name;
    if (pageNameKey) return getStringByKey(pageNameKey);
  })();

  const contentWrapperStyles = css({
    minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
  });

  const whatsappIcon = css({
    position: 'fixed',
    bottom: '16px',
    right: '16px',
  });

  const whatsappImage = css({
    height: '60px',
    width: '60px',
    objectFit: 'contain',
    [mediaQueries.lg]: {
      height: '80px',
      width: '80px',
    },
  });

  return (
    <>
      <Helmet>
        <title>Impulso {title ? `- ${title}` : ''}</title>
      </Helmet>
      <AppProvider />
      <ScrollerProvider />
      <Navbar />
      <div className={contentWrapperStyles}>
        <Outlet />
      </div>
      <Footer />
      <a href={strings.shared.whatsappUrl} target="_blank" rel="noreferrer" className={whatsappIcon}>
        <img src="../img/icons/whatsapp.png" alt="whatsapp" className={whatsappImage} />
      </a>
    </>
  );
}
