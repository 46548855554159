import { useStyles } from '../../../hooks/useStyles';

type Props = {
  testimonial: {
    fullName: string;
    position: string;
    quote: string;
    imageUrl: string;
  };
};
export function TestimonialCard({ testimonial }: Readonly<Props>) {
  const { classes, css, concatenateClasses, mediaQueries, theme, styles } = useStyles();

  const benefitCardContentStyles = concatenateClasses(
    classes.fullWidth,
    css({
      ...styles.flexColumnStyles,
      background: theme.colors.clearBackground,
      borderRadius: '15px',
      boxSizing: 'border-box',
      gap: theme.sizing.xl,
      marginBottom: '30px',
      padding: '40px',
      [mediaQueries.xl]: {
        flexDirection: 'row',
        gap: theme.sizing.lg,
        minHeight: '450px',
      },
    })
  );

  const columLContentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'center',
      flex: 3,
      justifyContent: 'center',
    })
  );

  const columSmContentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'center',
      flex: 2.5,
      justifyContent: 'center',
    })
  );

  const fullNameStyles = css({
    color: theme.colors.secondary[1],
    fontSize: '15px',
    fontWeight: '600',
    letterSpacing: '1px',
    textAlign: 'center',
    [mediaQueries.xxl]: {
      fontSize: '20px',
      letterSpacing: '2px',
    },
  });

  const positionStyles = css({
    fontSize: '14px',
    fontStyle: 'italic',
    letterSpacing: '1px',
    marginTop: '4px',
    textAlign: 'center',
    width: '80%',
  });

  const quotesContent = css({
    position: 'relative',
    width: '100%',
  });

  const quoteStyles = css({
    boxSizing: 'border-box',
    fontSize: '14px',
    fontStyle: 'italic',
    padding: '0 30px',
    textAlign: 'center',
    [mediaQueries.md]: {
      fontSize: '16px',
    },
    [mediaQueries.xxl]: {
      fontSize: '18px',
      padding: '0 40px',
    },
  });

  const openQuotesStyles = css({
    left: 0,
    position: 'absolute',
    top: '-10px',
    width: '30px',
    [mediaQueries.xxl]: {
      width: '40px',
    },
  });

  const closeQuotesStyles = css({
    bottom: '-12px',
    position: 'absolute',
    right: '0px',
    width: '30px',
    [mediaQueries.xxl]: {
      width: '40px',
    },
  });

  const imgStyles = concatenateClasses(
    classes.fullWidth,
    css({
      maxHeight: '150px',
      objectFit: 'contain',
      [mediaQueries.xxl]: {
        maxHeight: 'none',
      },
    })
  );

  return (
    <div className={benefitCardContentStyles}>
      <div className={columSmContentStyles}>
        <img src={testimonial.imageUrl} alt={testimonial.fullName} className={imgStyles} />
        <p className={fullNameStyles}>{testimonial.fullName}</p>
        <p className={positionStyles}>{testimonial.position}</p>
      </div>
      <div className={columLContentStyles}>
        <div className={quotesContent}>
          <img src="../img/icons/openquotes.png" alt="Inpulso website" className={openQuotesStyles} />
          <p className={quoteStyles}>{testimonial.quote}</p>
          <img src="../img/icons/closequotes.png" alt="Inpulso website" className={closeQuotesStyles} />
        </div>
      </div>
    </div>
  );
}
