import { RouteObject } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { enRoutesState, esRoutesState } from '../storage/localeStates';
import { Page } from '../types/app';
import { EN_LANGUAGE, ES_LANGUAGE } from './../constants/locales';
import { useStrings } from './useStrings';

export function usePageRoute() {
  const { getStringByKeyFromStrings } = useStrings();
  const enRoutes = useRecoilValue(enRoutesState);
  const esRoutes = useRecoilValue(esRoutesState);

  const getRoutePage = (rootPage: Page, lang: typeof ES_LANGUAGE | typeof EN_LANGUAGE): RouteObject => {
    const routes = lang === ES_LANGUAGE ? esRoutes : enRoutes;
    return {
      id: getStringByKeyFromStrings(routes, rootPage.id),
      path: getStringByKeyFromStrings(routes, rootPage.route),
      element: rootPage.component,
    };
  };

  return {
    getRoutePage,
  };
}
