import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { Carousel } from '../shared/carousel/Carousel';
import { TestimonialCard } from './testiminial/TestimonialCard';

export function TestimonialsSection() {
  const { classes, concatenateClasses, css, theme, mediaQueries, styles } = useStyles();
  const { strings } = useStrings();

  const testimonialContentStyles = concatenateClasses(
    classes.flexColumn,
    css({
      flex: 2.5,
      justifyContent: 'center',
      textAlign: 'center',
      [mediaQueries.xl]: {
        textAlign: 'start',
      },
    })
  );

  const testimonialContentCardStyles = concatenateClasses(
    classes.flexColumn,
    css({
      flex: 3,
    })
  );

  const titleStyles = css({
    marginTop: '4px',
    fontSize: '32px',
    fontWeight: 'bold',
    color: theme.colors.primary[0],
    [mediaQueries.xxl]: {
      fontSize: '42px',
    },
  });

  const descriptionStyles = css({
    marginTop: '8px',
    fontSize: '16px',
    letterSpacing: '1px',
    [mediaQueries.xxl]: {
      marginTop: '12px',
      fontSize: '20px',
    },
  });

  const sectionStyles = concatenateClasses(
    classes.container,
    classes.section,
    css({
      ...styles.flexColumnStyles,
      gap: theme.spacing.xl,
      [mediaQueries.xl]: {
        flexDirection: 'row',
        gap: '40px',
      },
    })
  );

  return (
    <div className={classes.backgroundLight}>
      <section id={strings.sections.testimonials.id} className={sectionStyles}>
        <div className={testimonialContentStyles}>
          <h1 className={titleStyles}>{strings.testimonials.title}</h1>
          <p className={descriptionStyles}>{strings.testimonials.description}</p>
        </div>
        <div className={testimonialContentCardStyles}>
          <Carousel
            options={Object.values(strings.testimonials.carousel).map((item, index) => (
              <TestimonialCard key={item.fullName} testimonial={item} />
            ))}
            hiddenArrows
          />
        </div>
      </section>
    </div>
  );
}
