import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { useStyles } from '../../hooks/useStyles';
import { ButtonComponent } from '../../types/componentTypes';

export function Button({
  children,
  classNames,
  onClick,
  disabled = false,
  outline = false,
  size = 'default',
  type = 'button',
}: Readonly<ButtonComponent>) {
  const { classes, concatenateClasses, theme, css } = useStyles();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const buttonColor = (() => {
    if (disabled) {
      return theme.colors.primaryDisabledBackground;
    }
    if (outline) {
      return 'transparent';
    }
    return isHover ? theme.colors.primaryHoverBackground : theme.colors.primary[0];
  })();

  const buttonForegroundColor = (() => {
    if (outline) {
      return theme.colors.primary[0];
    }
    return '#ffffff';
  })();

  const buttonPadding = (() => {
    if (size === 'large') {
      return `${theme.spacing.lg} ${theme.spacing.xxl}`;
    }
    return `${theme.spacing.md} ${theme.spacing.lg}`;
  })();

  const buttonBorder = (() => {
    if (outline) {
      return `1px solid ${buttonForegroundColor}`;
    }
    return 'none';
  })();

  const buttonStyles = css({
    backgroundColor: buttonColor,
    color: buttonForegroundColor,
    padding: buttonPadding,
    border: buttonBorder,
    cursor: disabled ? 'not-allowed' : 'pointer',
  });

  const buttonAllStyles = concatenateClasses(classes.button, buttonStyles, classNames ?? '');

  return (
    <button ref={hoverRef} disabled={disabled} type={type} onClick={onClick} className={buttonAllStyles}>
      {children}
    </button>
  );
}
