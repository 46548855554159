import { useStrings } from '../../../hooks/useStrings';
import { useStyles } from '../../../hooks/useStyles';

type Props = {
  title: string;
  description: string;
  img: string;
  centeredText?: boolean;
  linkTo: string;
};
export function PlatformCard({ title, description, img, centeredText = false, linkTo }: Readonly<Props>) {
  const { strings } = useStrings();
  const { css, theme, mediaQueries, concatenateClasses, classes } = useStyles();

  const cardStyles = concatenateClasses(
    classes.flexColumn,
    css({
      backgroundColor: theme.colors.clearBackground,
      borderRadius: theme.sizing.md,
      boxSizing: 'border-box',
      flex: 1,
      gap: theme.spacing.xl,
      minWidth: '200px',
      padding: `40px`,
      position: 'relative',
      textAlign: 'center',
    })
  );

  const titleStyles = css({
    color: theme.colors.primary[0],
    fontSize: theme.sizing.xl,
    fontWeight: '600',
    [mediaQueries.lg]: {
      fontSize: theme.sizing.xxl,
    },
  });

  const descriptionStyles = css({
    letterSpacing: '1px',
    lineHeight: 1.3,
    textAlign: centeredText ? 'center' : 'justify',
    [mediaQueries.lg]: {
      fontSize: '20px',
    },
  });

  const decoratorStyles = css({
    alignSelf: 'center',
    margin: `${theme.sizing.lg} 0`,
    maxHeight: '100px',
    maxWidth: '200px',
    objectFit: 'contain',
    [mediaQueries.xl]: {
      maxHeight: '150px',
      maxWidth: '300px',
    },
  });

  const decoratorButtonStyles = css({
    alignSelf: 'center',
    background: theme.colors.primaryBackground,
    borderRadius: theme.sizing.xs,
    color: theme.colors.secondary[0],
    fontWeight: '600',
    letterSpacing: '1px',
    padding: `${theme.spacing.md} ${theme.spacing.xl}`,
    textDecoration: 'none',
    transition: '0.3s background',
    ':hover': {
      background: theme.colors.primaryHoverBackground,
    },
  });

  return (
    <div className={cardStyles}>
      <p className={titleStyles}>{title}</p>
      <p className={descriptionStyles}>{description}</p>
      <img src={img} alt={title} className={decoratorStyles} />
      {linkTo !== '' && (
        <a href={linkTo} className={decoratorButtonStyles} target="_blank" rel="noreferrer">
          {strings.shared.enterButton}
        </a>
      )}
    </div>
  );
}
