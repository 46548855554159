import THEME from '../../constants/theme';

type Props = {
  height?: string;
  width?: string;
  color?: string;
};
export function MenuIcon({ height = '20px', width = '20px', color = THEME.colors.foregroundColor }: Readonly<Props>) {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 -960 960 960" width={width}>
      <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
    </svg>
  );
}
