const THEME = {
  colors: {
    primary: ['#f68720', '#f5e0cc'],
    secondary: ['#ffffff', '#ec8830'],
    primaryBackground: '#f68720',
    primaryHoverBackground: 'rgb(254 153 59)',
    primaryDisabledBackground: 'rgb(248 182 121)',
    primaryLightBackground: '#f5e0cc',
    lightBackground: '#f0f2f5',
    clearBackground: '#ffffff',
    foregroundColor: '#434343',
  },
  breakpoints: {
    xs: '0',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  sizing: {
    xs: '4px',
    sm: '6px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    xxl: '32px',
  },
  spacing: {
    zero: '0px',
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '12px',
    xl: '16px',
    xxl: '20px',
  },
};

export default THEME;
