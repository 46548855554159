import { AlliadesSection } from '../home/AlliadesSection';
import { BenefitsSection } from '../home/BenefitsSection';
import { ContactSection } from '../home/ContactSection';
import { PlatformsSection } from '../home/PlatformsSection';
import { TestimonialsSection } from '../home/TestimonialsSection';

export function HomePage() {
  return (
    <>
      <PlatformsSection />
      <BenefitsSection />
      <AlliadesSection />
      <TestimonialsSection />
      <ContactSection />
    </>
  );
}
