import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { BenefitCard } from './benefit/BenefitCard';

export function BenefitsSection() {
  const { classes, css, concatenateClasses, theme } = useStyles();
  const { strings } = useStrings();

  const benefitContent = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      color: theme.colors.secondary[0],
      gap: theme.spacing.xxl,
      justifyContent: 'center',
      minHeight: '200px',
      position: 'relative',
      textAlign: 'center',
      width: '100%',
    })
  );

  const benefitsContentList = concatenateClasses(classes.fullWidth, classes.flexColumn, css({ gap: '40px' }));

  const titleStyles = concatenateClasses(
    classes.fullWidth,
    classes.sectionTitle,
    css({
      fontWeight: 'bold',
      lineHeight: '1.3',
      marginBottom: '0px !important',
    })
  );

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  return (
    <div className={classes.backgroundPrimary}>
      <section id={strings.sections.benefits.id} className={sectionStyles}>
        <div className={benefitContent}>
          <h1 className={titleStyles}>{strings.benefits.title}</h1>
          <div className={benefitsContentList}>
            {Object.values(strings.benefits.list).map((item, index) => (
              <BenefitCard key={item.title} benefit={item} invPosition={(index + 1) % 2 === 0} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}
