import { useEffect } from 'react';
import { Link, useMatches } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useWindowSize } from 'usehooks-ts';
import { EN_LANGUAGE, ES_LANGUAGE } from '../../constants/locales';
import { NAVBAR_HEIGHT } from '../../constants/ui';
import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { openMenuState } from '../../storage/appStates';
import { currentLanguageState } from '../../storage/localeStates';
import { Button } from '../ui/Button';
import { MenuIcon } from '../ui/MenuIcon';

export function Navbar() {
  const { css, theme, classes, concatenateClasses, mediaQueries } = useStyles();
  const { strings } = useStrings();
  const [openMenu, setOpenMenu] = useRecoilState(openMenuState);
  const { width } = useWindowSize();
  const language = useRecoilValue(currentLanguageState);
  const isMobile = width < 768;
  const matches = useMatches();
  const isHome = matches[matches.length - 1].id === strings.home.id;

  useEffect(() => {
    if (width >= 768) {
      setOpenMenu(true);
    } else {
      setOpenMenu(false);
    }
  }, [width]);

  const navbarStyles = css({
    background: theme.colors.clearBackground,
    boxShadow: 'rgb(147 147 147 / 50%) 0px 2px 10px 0px',
    boxSizing: 'border-box',
    minHeight: `${NAVBAR_HEIGHT}px`,
    padding: `${theme.spacing.md} 0 ${openMenu ? theme.spacing.xl : theme.spacing.md}`,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    [mediaQueries.md]: {
      height: `${NAVBAR_HEIGHT}px`,
      padding: `${theme.spacing.md} 0`,
    },
  });

  const logoStyles = css({
    height: '60px',
    objectFit: 'contain',
  });

  const navigationStyles = css({
    alignItems: 'start',
    display: openMenu ? 'flex' : 'none',
    flexDirection: 'column',
    gap: theme.spacing.xxl,
    justifyContent: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
    [mediaQueries.md]: {
      flexDirection: 'row',
      height: 'auto',
      width: 'auto',
    },
  });

  const navigationWrapper = concatenateClasses(
    classes.container,
    css({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing.xl,
      height: '100%',
      justifyContent: 'space-between',
      [mediaQueries.md]: {
        flexDirection: 'row',
      },
    })
  );

  const mainNav = concatenateClasses(
    classes.flexRow,
    css({
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      [mediaQueries.md]: {
        width: 'auto',
      },
    })
  );

  const menuStyles = css({
    background: 'transparent',
    [mediaQueries.md]: {
      display: 'none',
    },
  });

  const selectedLanguageStyles = css({
    fontWeight: '600',
  });

  const dividerStyles = css({
    background: 'lightgray',
    height: theme.sizing.lg,
    width: '1px',
  });

  const languageWrapperStyles = concatenateClasses(
    classes.flexRow,
    css({
      alignItems: 'center',
      gap: theme.spacing.md,
    })
  );

  const menuWrapperStyles = concatenateClasses(classes.flexRow, css({ alignItems: 'center', gap: theme.spacing.md }));

  const toggleMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const onNavClick = () => {
    if (isMobile && openMenu) {
      setOpenMenu(false);
    }
  };

  const navLink = concatenateClasses(classes.link, classes.textPrimary);

  const filteredSections = Object.values(strings.sections).filter((section) => section.showInNav === 'true');

  return (
    <nav className={navbarStyles}>
      <div className={navigationWrapper}>
        <div className={mainNav}>
          <Link to={matches[1].pathname}>
            <img src="../img/impulso/impulso.png" alt="Impulso logo" className={logoStyles} />
          </Link>
          <Button onClick={toggleMenu} classNames={menuStyles} outline>
            <div className={menuWrapperStyles}>
              <MenuIcon color={theme.colors.primary[0]} />
            </div>
          </Button>
        </div>
        <ul className={navigationStyles}>
          {isHome &&
            filteredSections.map((section) => (
              <li key={section.id}>
                <Link to={section.route} className={navLink} onClick={onNavClick}>
                  {section.name.toUpperCase()}
                </Link>
              </li>
            ))}
          <li>
            <div className={languageWrapperStyles}>
              <Link
                to={'/'}
                className={concatenateClasses(classes.link, language === ES_LANGUAGE ? selectedLanguageStyles : '')}
              >
                ES
              </Link>
              <div className={dividerStyles} />
              <Link
                to={`/${EN_LANGUAGE}`}
                className={concatenateClasses(classes.link, language === EN_LANGUAGE ? selectedLanguageStyles : '')}
              >
                EN
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
