import { API } from 'aws-amplify';
import { environment } from '../environment';
import { ApiError } from '../types/apiResponseError';
import { getErrorMessage } from '../utils/apiErrorResponse';

export const postRequest = async (path: string, params = {}): Promise<{ data?: any; error?: ApiError }> => {
  try {
    return {
      data: await API.post(environment.awsAPIEndpointName, path, params),
    };
  } catch (error) {
    console.log(error);
    return {
      error: getErrorMessage(error),
    };
  }
};
