import { KeyOfLocales } from '../types/objectTypes';

export const validatePhoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const validateEmailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const validateEmail = (email: string): KeyOfLocales | undefined => {
  if (!validateEmailRegex.test(email)) {
    return 'contact.invalidEmail';
  }
};

export const validatePhone = (phone: string): KeyOfLocales | undefined => {
  if (!validatePhoneRegex.test(phone)) {
    return 'contact.invalidPhoneNumber';
  }
};
