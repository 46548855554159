import { Link } from 'react-router-dom';
import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';

export function Footer() {
  const { classes, css, concatenateClasses, styles, theme, mediaQueries } = useStyles();
  const { strings } = useStrings();
  const sectionStyles = concatenateClasses(
    classes.container,
    classes.textSecondary,
    css({
      paddingTop: '40px',
      paddingBottom: '40px',
      fontWeight: '500',
    })
  );

  const footerContent = concatenateClasses(
    css({
      ...styles.flexColumnStyles,
      gap: '40px',
      [mediaQueries.xl]: {
        flexDirection: 'row',
        gap: theme.spacing.xxl,
      },
    })
  );

  const logoStyles = css({
    height: '60px',
    objectFit: 'contain',
  });

  const sectionTitle = css({
    marginBottom: theme.spacing.md,
    fontWeight: '500',
  });

  const contactIcon = css({
    height: '20px',
    width: '20px',
    objectFit: 'contain',
  });

  const contactLink = concatenateClasses(
    classes.link,
    css({
      display: 'flex',
      gap: theme.spacing.md,
    })
  );
  const footerColumn = (flex: number) => concatenateClasses(classes.flexColumn, css({ flex, gap: theme.spacing.lg }));

  const hasAnySocialUrl = (() => {
    if (strings.footer.follow.facebookUrl || strings.footer.follow.twitterUrl || strings.footer.follow.instagramUrl)
      return true;
    return false;
  })();

  return (
    <div className={classes.backgroundPrimary}>
      <section className={sectionStyles}>
        <div className={footerContent}>
          <div className={css({ flex: 3 })}>
            <img src="../img/impulso/impulso-white.png" className={logoStyles} alt="Footer logo" />
          </div>
          <div className={footerColumn(1)}>
            <p className={sectionTitle}>{strings.footer.sitemap}</p>
            {Object.values(strings.sections).map((section) => (
              <Link key={section.id} to={section.route} className={classes.link}>
                {section.name}
              </Link>
            ))}
          </div>
          <div className={footerColumn(2)}>
            <p className={sectionTitle}>{strings.footer.contactData.title}</p>
            <a href={strings.footer.contactData.address.url} className={contactLink} target="_blank" rel="noreferrer">
              <img src={'../img/icons/location.svg'} alt="Pin icon" className={contactIcon} />{' '}
              {strings.footer.contactData.address.text}
            </a>
            <a
              href={`mailto:${strings.footer.contactData.email}`}
              className={contactLink}
              target="_blank"
              rel="noreferrer"
            >
              <img src={'../img/icons/mail.svg'} alt="Mail icon" className={contactIcon} />{' '}
              {strings.footer.contactData.email}
            </a>
          </div>
          {hasAnySocialUrl && (
            <div className={footerColumn(1)}>
              <p className={sectionTitle}>{strings.footer.follow.title}</p>
              {strings.footer.follow.facebookUrl !== '' && (
                <a href={strings.footer.follow.facebookUrl} className={classes.link} target="_blank" rel="noreferrer">
                  Facebook
                </a>
              )}
              {strings.footer.follow.twitterUrl !== '' && (
                <a href={strings.footer.follow.twitterUrl} className={classes.link} target="_blank" rel="noreferrer">
                  Twitter
                </a>
              )}
              {strings.footer.follow.instagramUrl !== '' && (
                <a href={strings.footer.follow.instagramUrl} className={classes.link} target="_blank" rel="noreferrer">
                  Instagram
                </a>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
