import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { EMPTY_CONTACT_FORM } from '../../../constants/app';
import { useStrings } from '../../../hooks/useStrings';
import { useStyles } from '../../../hooks/useStyles';
import { postContactForm } from '../../../services/app';
import { validateEmail, validatePhone } from '../../../utils/regex';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';

export function ContactForm() {
  const { classes, theme, concatenateClasses, css, mediaQueries, styles } = useStyles();
  const { strings, getStringByKey } = useStrings();
  const [form, setForm] = useState(EMPTY_CONTACT_FORM);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const captchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const emailError = form.email ? validateEmail(form.email) : undefined;
  const phoneError = form.phone ? validatePhone(form.phone) : undefined;

  const contactWrapperStyles = concatenateClasses(
    classes.flexColumn,
    css({
      flex: 1,
      gap: theme.spacing.xl,
      alignItems: 'center',
    })
  );

  const titleStyles = css({
    fontSize: '32px',
    fontWeight: '500',
    color: theme.colors.primary[0],
    marginBottom: theme.spacing.lg,
    [mediaQueries.xxl]: {
      fontSize: '38px',
    },
  });

  const rowStyles = concatenateClasses(
    classes.fullWidth,
    css({
      ...styles.flexColumnStyles,
      gap: theme.spacing.xl,
      [mediaQueries.sm]: {
        flexDirection: 'row',
      },
    })
  );

  const inputErrorWrapper = concatenateClasses(classes.flexColumn, classes.fullWidth, css({ gap: theme.spacing.md }));

  const inputErrorStyles = css({
    fontSize: theme.sizing.md,
    color: 'red',
  });

  const bannerStyles = (error: boolean) =>
    concatenateClasses(
      classes.fullWidth,
      css({
        boxSizing: 'border-box',
        padding: theme.spacing.xl,
        borderRadius: theme.sizing.md,
        backgroundColor: error ? 'rgb(255, 239, 237)' : 'rgb(230, 242, 237)',
        fontWeight: '500',
      })
    );

  const onInputChange = (value: any, key: keyof typeof form) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetForm = () => {
    setForm(EMPTY_CONTACT_FORM);
  };

  const handleSubmit = async () => {
    if (!captchaToken) return;
    const { error } = await postContactForm(form, captchaToken);

    if (!error) {
      resetForm();
      setSuccess(strings.contact.successfulSubmission);
    } else {
      setError(strings.contact.failedSubmission);
    }

    setCaptchaToken(null);
    captchaRef.current?.reset();

    setTimeout(() => {
      setSuccess('');
      setError('');
    }, 3000);
  };

  const formIsValid = (() => {
    if (emailError || !form.name || !form.message || phoneError) {
      return false;
    }
    return true;
  })();

  return (
    <div className={contactWrapperStyles}>
      <h2 className={titleStyles}>{strings.contact.contactUs}</h2>
      <Input
        value={form.name}
        onChange={(value) => onInputChange(value, 'name')}
        placeholder={strings.contact.nameText}
      />
      <div className={rowStyles}>
        <div className={inputErrorWrapper}>
          <Input
            value={form.email}
            onChange={(value) => onInputChange(value, 'email')}
            placeholder={strings.contact.emailText}
            type="email"
          />
          {emailError && <p className={inputErrorStyles}>{getStringByKey(emailError)}</p>}
        </div>
        <div className={inputErrorWrapper}>
          <Input
            value={form.phone}
            onChange={(value) => onInputChange(value, 'phone')}
            placeholder={strings.contact.phoneText}
            type="tel"
          />
          {phoneError && <p className={inputErrorStyles}>{getStringByKey(phoneError)}</p>}
        </div>
      </div>
      <Input
        value={form.message}
        onChange={(value) => onInputChange(value, 'message')}
        placeholder={strings.contact.messageText}
        isTextArea
      />
      {captchaKey && (
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={captchaKey}
          onChange={(token) => setCaptchaToken(token)}
          onExpired={() => setCaptchaToken(null)}
          onErrored={() => setCaptchaToken(null)}
        />
      )}
      {!error && !success && (
        <Button onClick={handleSubmit} size="large" disabled={!captchaToken || !formIsValid}>
          {strings.shared.sendButton}
        </Button>
      )}
      {error && (
        <div className={bannerStyles(true)}>
          <p>{strings.contact.failedSubmission}</p>
        </div>
      )}
      {success && (
        <div className={bannerStyles(false)}>
          <p>{strings.contact.successfulSubmission}</p>
        </div>
      )}
    </div>
  );
}
