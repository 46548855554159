import { useState } from 'react';
import { useStrings } from '../../hooks/useStrings';
import { useStyles } from '../../hooks/useStyles';
import { PlatformCard } from './platform/PlatformCard';

export function PlatformsSection() {
  const { classes, css, concatenateClasses, mediaQueries, theme, styles } = useStyles();
  const { strings } = useStrings();
  const [activeTab, setActiveTab] = useState(0);

  const platformContent = concatenateClasses(
    classes.flexColumn,
    css({
      alignItems: 'flex-start',
      boxSizing: 'border-box',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      textAlign: 'center',
    })
  );

  const platformContentLayout = concatenateClasses(
    classes.fullWidth,
    css({
      ...styles.flexColumnStyles,
      alignItems: 'center',
      gap: theme.spacing.xxl,
      [mediaQueries.xl]: {
        flexDirection: 'row',
        gap: theme.spacing.xl,
      },
    })
  );

  const titleStyles = concatenateClasses(
    classes.fullWidth,
    classes.sectionTitle,
    css({
      color: theme.colors.primary[0],
      fontWeight: 'bold',
      lineHeight: '1.3',
    })
  );

  const tabsContentStyles = concatenateClasses(
    classes.flexColumn,
    classes.fullWidth,
    css({
      alignSelf: 'flex-start',
      flex: 3,
      gap: '24px',
      [mediaQueries.lg]: {
        gap: '32px',
      },
    })
  );

  const tabsOptionsStyles = concatenateClasses(classes.flexRow, classes.fullWidth);

  const decoratorButtonStyles = css({
    background: theme.colors.primaryLightBackground,
    border: '1px solid ' + theme.colors.primary[0],
    boxShadow: 'none',
    color: theme.colors.primary[0],
    cursor: 'pointer',
    fontSize: theme.sizing.md,
    outline: 'transparent',
    padding: `${theme.spacing.md} ${theme.spacing.xl}`,
    width: '100%',
    ':hover': {
      background: theme.colors.primaryBackground,
      color: theme.colors.secondary[0],
    },
    ':nth-child(n + 2)': {
      borderLeft: 'none',
    },
    ':first-of-type': {
      borderRadius: '5px 0px 0px 5px',
    },
    ':last-of-type': {
      borderRadius: '0px 5px 5px 0px',
    },
    [mediaQueries.lg]: {
      fontSize: '14px',
    },
  });

  const decoratorActiveButtonStyles = css({
    background: theme.colors.primaryBackground,
    color: theme.colors.secondary[0],
  });

  const imgStyles = css({
    maxWidth: '500px',
    width: '100%',
  });

  const sectionStyles = concatenateClasses(classes.container, classes.section);

  return (
    <div className={classes.backgroundPrimaryLight}>
      <section id={strings.sections.platforms.id} className={sectionStyles}>
        <div className={platformContent}>
          <h1 className={titleStyles}>{strings.platforms.title}</h1>
          <div className={platformContentLayout}>
            <div className={tabsContentStyles}>
              <div className={tabsOptionsStyles}>
                {Object.values(strings.platforms.carousel).map((tab, index) => (
                  <button
                    key={index}
                    className={`
                      ${decoratorButtonStyles} 
                      ${index === activeTab ? decoratorActiveButtonStyles : ''}
                    `}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.title.toUpperCase()}
                  </button>
                ))}
              </div>
              <PlatformCard
                title={strings.platforms.carousel[activeTab].title}
                description={strings.platforms.carousel[activeTab].description}
                img={strings.platforms.carousel[activeTab].imageUrl}
                linkTo={strings.platforms.carousel[activeTab].linkTo}
              />
            </div>
            <div className={concatenateClasses(classes.fullWidth, css({ flex: 2.5 }))}>
              <img src={strings.platforms.imageUrl} alt="Inpulso website" className={imgStyles} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
